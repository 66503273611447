<template>
  <b-container>
    <b-row>
      <b-col>
        <b-button variant="success" @click="printsDOCX">Print</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { saveAs } from "file-saver";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
export default {
  components: {
    saveAs,
  },
  data() {
    return {
      doc: null,
    };
  },
  methods: {
    printsDOCX() {
      console.log("iki");
      loadFile(
        "http://localhost:8009/asset/kelengkapan/Petugas_Kebersihan.docx",
        function (error, content) {
          if (error) {
            throw error;
          }
          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });
          doc.setData({
            first_name: "John",
            last_name: "Doe",
            phone: "0652455478",
            description: "New Website",
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                },
                {});
              }
              return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join("\n");
              console.log("errorMessages", errorMessages);
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          const out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          // Output the document using Data-URI
          saveAs(out, "output.docx");
        }
      );
    },
  },
  created() {},
};
</script>